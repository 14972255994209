import React, { useEffect, useMemo, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CircularProgress, List } from '@material-ui/core'
import 'modules/documents/css/documents_list.scss'
import 'css/list.scss'
import QuotationListFiltersComponent from 'modules/quotations/components/list/quotationListFilters.component'
import { IQuotationListFilter } from 'app/types/quotations'
import QuotationListItem from 'modules/quotations/components/list/quotationListItem.component'
import { useOnView } from 'app/providers/onview.provider'

const QuotationsList = (): JSX.Element => {
  const { t } = useTranslation()
  const { quotationListUseQuery } = useOnView()
  const { data: quotations, isLoading } = quotationListUseQuery!()

  const [filters, setFilters] = useState<IQuotationListFilter>({ state: 0 })

  const filteredQuotations = useMemo(() => {
    if (!quotations) return []
    if (filters.state === 0) return [...quotations]
    return [...quotations].filter((q) => q.state === filters.state)
  }, [quotations, filters])

  const states = useMemo(() => {
    if (!quotations) return []
    const sts = quotations.map((q) => ({ value: q.state, label: q.stateLabel }))
    const map = new Map<number, {value: number, label: string}>()
    for (const item of sts) map.set(item.value, item)
    return Array.from(map.values())
  }, [quotations])

  useEffect(() => {
    const newFilterState = states.find((s) => s.value === 1) ? 1 : 0
    setFilters({ state: newFilterState })
  }, [states])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={1}>
          <Box p={2}>
            <div className="myCard">
              <div className="card-header list-header">
                <Link to="/home" color="primary" className="icon-button">
                  <ArrowBackIcon fontSize="small" />
                </Link>
                <Typography variant="h2" color="primary">
                  {t('common.my_quotations')}
                </Typography>
              </div>


              {isLoading && (
                <div className="myCardLoader">
                  <CircularProgress />
                </div>
              )}

              {!isLoading && <QuotationListFiltersComponent
                filters={filters}
                onChangeFilters={(f) => setFilters(f)}
                states={states}
              />}

              {filteredQuotations.length === 0 && !isLoading && (
                <Typography variant="body2" color="textSecondary">
                  {t('common.no_quotation')}
                </Typography>
              )}

              <div className="quotations_content">
                <List className="myListItem">
                  {filteredQuotations.map((quotation) => (
                    <QuotationListItem key={quotation.id} quotation={quotation} />
                  ))}
                </List>
              </div>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default QuotationsList
